import {ClientProvider} from '@packages/client';
import {QueryClientProvider} from '@tanstack/react-query';
// Import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import {InvalidTokenError} from '@packages/utils';
import {type NextPage} from 'next';
import {type AppProps} from 'next/app';
import {useRouter} from 'next/router';
import Head from 'next/head';
import Image from 'next/image';
import {type ReactElement, type ReactNode} from 'react';
import {RecoilRoot} from 'recoil';
import {destroyCookie, parseCookies} from 'nookies';
import {FAVICON_PATH, OGP_IMAGE_PATH_DEFAULT} from '../config/constants/img';
import {bugsnag} from '../lib/bugsnag';
import {queryClient} from '../lib/react-query';
import {initMocks} from '../mocks';
import {ClientSideErrorPage} from '../shared/components/Error/ClientSideErrorPage';
import {RegistrationNavigationFloating} from '../shared/components/RegistrationNavigationFloating';
import {ScoutNotificationModal} from '../shared/components/ScoutNotificationModal';
import '../styles/globals.css';
import {
  cookieApiRefreshTokenKey,
  cookieApiTokenKey,
} from '../server/token/cookieOauthTokenKey';
import {handleRefreshAccessToken} from '../core/usecases/auth/handleRefreshAccessToken';
import {checkRefreshTokenExistence} from '../core/usecases/auth/checkOauthTokenExistence';
import {sendOauthTokenToRNApp} from '../core/usecases/auth/sendOauthTokenToRNApp';
import {SessionExpiredPage} from '../shared/components/Session/SessionExpiredPage';
import {SessionExpiredPopup} from '../shared/components/Session/SessionExpiredPopup';
import {cookieLightUserRequestKey} from '../server/token/cookieLightUserRequest';
import {checkLightUserRequestFlagExistence} from '../core/usecases/auth/checkLightUserRequest';
import {destroyCookieWithLogout} from '../core/usecases/auth/destroyCookieWithLogout';

// MEMO: APIモックを有効化する
// 有効にしない場合は、process.env.NEXT_PUBLIC_API_MOCKINGを空にする
// process.env.NEXT_PUBLIC_API_MOCKING === ""
if (
  process.env.NODE_ENV === 'development' &&
  process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'
) {
  void initMocks();
}

bugsnag();

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const REFRESH_ACCESS_TOKEN_TIMER_ID = 'refreshAccessTokenTimerId';

const App = ({Component, pageProps}: AppPropsWithLayout): JSX.Element => {
  if (typeof window !== 'undefined') {
    const router = useRouter();
    const cookies = parseCookies();

    const {isValid: isValidRefreshToken} = checkRefreshTokenExistence(
      cookies[cookieApiRefreshTokenKey],
    );
    const {isValid: isLightUserRequest} = checkLightUserRequestFlagExistence(
      cookies[cookieLightUserRequestKey],
    );

    if (isLightUserRequest) {
      if (isValidRefreshToken) {
        // ライトユーザーの場合で前回ログイン時のCookieが残っている場合はCookieを削除してリロードする
        destroyCookieWithLogout();
        router.reload();
      }

      // 再ログイン時の影響を考慮して、ライトユーザー判別用のCookieを削除する
      destroyCookie(null, cookieLightUserRequestKey, {path: '/'});
    }

    // AccessTokenとrefreshTokenをアプリ側に送信する
    if (
      isValidRefreshToken &&
      (window as any).ReactNativeWebView &&
      !isLightUserRequest
    ) {
      sendOauthTokenToRNApp({
        accessToken: cookies[cookieApiTokenKey] ?? '',
        refreshToken: cookies[cookieApiRefreshTokenKey] ?? '',
      });
    }

    // 既存のAccessToken更新のタイマーをクリア
    const savedTimerId = window.localStorage.getItem(
      REFRESH_ACCESS_TOKEN_TIMER_ID,
    );
    if (savedTimerId) {
      window.clearTimeout(Number(savedTimerId));
    }

    // ログイン状態の場合は4時間ごとにAccessTokenを更新する
    if (isValidRefreshToken && !isLightUserRequest) {
      const timerId = window.setTimeout(async () => {
        await handleRefreshAccessToken({
          cookies,
          async request() {
            return {ok: true, value: null};
          },
        });
      }, 14_400_000);
      window.localStorage.setItem(REFRESH_ACCESS_TOKEN_TIMER_ID, `${timerId}`);
    }
  }

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const {error, resolvedUrl} = pageProps;
  const hasError =
    (error && error.name === InvalidTokenError) || error?.message;

  const ErrorComponent: React.FC = () => {
    if (error && error.name === InvalidTokenError) {
      return <SessionExpiredPage resolvedUrl={resolvedUrl} />;
    }

    if (error?.message) {
      return <ClientSideErrorPage message={error.message} />;
    }
  };

  return (
    <>
      <RecoilRoot>
        <ClientProvider>
          <QueryClientProvider client={queryClient}>
            <Head>
              <meta charSet="utf-8" />
              <meta
                name="viewport"
                content="width=device-width,initial-scale=1"
              />
              <meta name="format-detection" content="telephone=no" />
              <meta name="mobile-web-app-capable" content="yes" />

              <title key="title">外資就活ドットコム</title>
              <meta
                key="description"
                name="description"
                content="外資系・日系超一流企業を目指す学生のための就職活動サイト。トップ企業の求人情報から、過去の選考情報・先輩のエントリーシ>ート・選考突破法・TOEIC勉強法・インターンシップまで多様なコンテンツを掲載。選考情報一括管理できるカレンダー機能も"
              />
              <meta
                key="keywords"
                name="keywords"
                content="外資,就活,外資系,就職,投資銀行,コンサル,商社"
              />
              <meta key="og:type" property="og:type" content="website" />
              <meta
                key="og:title"
                property="og:title"
                content="外資就活ドットコム"
              />
              <meta
                key="og:site_name"
                property="og:site_name"
                content="外資就活ドットコム"
              />
              <meta
                key="og:description"
                property="og:description"
                content="外資系・日系超一流企業を目指す学生のための就職活動サイト。トップ企業の求人情報から、過去の選考情報・先輩のエントリーシート・選考突破法・TOEIC勉強法・インターンシップまで多様なコンテンツを掲載。選考情報一括管理できるカレンダー機能も有"
              />
              <meta
                key="og:image"
                property="og:image"
                content={`https://gaishishukatsu.com${OGP_IMAGE_PATH_DEFAULT}`}
              />
              <meta
                key="og:url"
                property="og:url"
                content="https://gaishishukatsu.com/"
              />
              <meta key="og:locale" property="og:locale" content="ja_JP" />

              <meta
                key="fb:app_id"
                property="fb:app_id"
                content="152514804805611"
              />
              <meta
                name="facebook-domain-verification"
                content="rdf1tzoc35bm3zphygpoqnr9dkghrd"
              />

              <meta
                key="twitter:card"
                name="twitter:card"
                content="summary_large_image"
              />
              <meta
                key="twitter:site"
                name="twitter:site"
                content="@gaishishukatsu"
              />

              <link
                rel="shortcut icon"
                type="image/x-icon"
                href={FAVICON_PATH}
              />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
            </Head>
            {hasError ? (
              <ErrorComponent />
            ) : (
              <>
                {getLayout(<Component {...pageProps} />)}
                <ScoutNotificationModal />
                <RegistrationNavigationFloating />
                <SessionExpiredPopup />
              </>
            )}
          </QueryClientProvider>
        </ClientProvider>
      </RecoilRoot>
      {/* Twitter conversion tracking base code */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
        !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        twq('config','oeh4c');
        `,
        }}
      />
      {/* End Twitter conversion tracking base code */}
      {/* LINE Tag Base Code  */}

      {/* !-- Do Not Modify */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(g,d,o){
            g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
            var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
            var s=d.createElement('script');s.async=1;
            s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
            var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
              })(window, document);
          _lt('init', {
            customerType: 'lap',
            tagId: '1010769f-1205-4450-a554-9f3e269d814c'
          });
          _lt('send', 'pv', ['1010769f-1205-4450-a554-9f3e269d814c']);
        `,
        }}
      />
      <noscript>
        <Image
          height="1"
          width="1"
          style={{display: 'none'}}
          src="https://tr.line.me/tag.gif?c_t=lap&t_id=1010769f-1205-4450-a554-9f3e269d814c&e=pv&noscript=1"
          alt="LINE Tag Base Code"
        />
      </noscript>
      {/* End LINE Tag Base Code */}

      {/* Meta Pixel Code */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
        !function(f,b,e,v,n,t,s){
          if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '533578025280022');
          fbq('track', 'PageView');
        `,
        }}
      />
      <noscript>
        <Image
          height="1"
          width="1"
          style={{display: 'none'}}
          src="https://www.facebook.com/tr?id=533578025280022&ev=PageView&noscript=1"
          alt="Meta Pixel Code"
        />
      </noscript>
      {/* End Meta Pixel Code */}

      {/** Microsoft Clarity */}
      {/* {process.env.NEXT_PUBLIC_RELEASE_STAGE === 'production' && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: ` (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "nr7yywna6p");`,
          }}
        ></script>
      )} */}
    </>
  );
};

export default App;
