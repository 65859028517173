export const DEGREE_TYPE_LIST = [
  'associate',
  'bachelor',
  'graduate',
  'doctor',
] as const;
export const DEGREE_TYPE_NAME = {
  associate: '準学士',
  bachelor: '学士',
  graduate: '修士',
  doctor: '博士',
} as const;

export type DegreeType = (typeof DEGREE_TYPE_LIST)[number];
export type DegreeTypeExcludeDoctor = Exclude<DegreeType, 'doctor'>;

export const KOSEN_GRADE_ID_LIST: number[] = [10, 11, 12, 13] as const;
export const KOSEN_BACHELOR_GRADE_ID_LIST: number[] = [12, 13] as const;
export const ASSOCIATE_GRADE_ID_LIST: number[] = [10, 11] as const;
export const BACHELOR_GRADE_ID_LIST: number[] = [
  1, 2, 3, 4, 5, 6, 12, 13,
] as const;
export const MASTER_GRADE_ID_LIST: number[] = [7, 8] as const;
export const DOCTOR_GRADE_ID_LIST: number[] = [9] as const;

export const UNIVERSITY_TYPE_LIST = ['university', 'kosen', 'foreign'] as const;
export type UniversityType = (typeof UNIVERSITY_TYPE_LIST)[number];

export type BunriNameType = 'humanity' | 'science';
export const BUNRI_TYPE_LIST = [1, 2] as const;
export type BunriNumberType = (typeof BUNRI_TYPE_LIST)[number];
export const BUNRI_DICTIONARY_TO_NUMBER: Record<
  BunriNameType,
  BunriNumberType
> = {
  humanity: 1,
  science: 2,
} as const;
