import {useRouter} from 'next/router';
import {useState, useEffect, useRef, type FC} from 'react';
import {FaSearch, FaExternalLinkAlt} from 'react-icons/fa';
import {
  MdAccountCircle,
  MdOutlineFiberNew,
  MdMenu,
  MdMail,
} from 'react-icons/md';
import {IoSearchCircleOutline} from 'react-icons/io5';
import {Input, Button, Transition, Drawer, Box, Text} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {parseCookies} from 'nookies';
import {routes} from '../../../config/constants';
import {
  LOGO_IMAGE_PATH_FULL,
  LOGO_IMAGE_PATH_SQUARE,
} from '../../../config/constants/img';
import {type UserType} from '../../../core/usecases/useAuth';
import {useAuthAndCounts} from '../../../core/usecases/useAuthAndCounts';
import {useBooleanState} from '../../services/useBooleanState';
import {overlayConfig} from '../modalConfig';
import {useMediaQuery} from '../../services/useMediaQuery';
import {validateCookie} from '../../../core/usecases/auth/checkOauthTokenExistence';
import {HeaderMenu} from './HeaderMenu';
import {HeaderReportMenu} from './HeaderReportMenu';
import {HeaderAuthActionMenu} from './HeaderAuthActionMenu';

type Props = {
  scoutsCount?: number;
  isIdle?: boolean;
  isSignedIn?: boolean;
  interestsCount?: number;
  className?: string;
  isPC?: boolean;
  signOut?: () => void;
};

type MenuItemProps = {
  item: {
    name: string;
    url: string;
    target?: string;
    isNew?: boolean;
    isUpdate?: boolean;
  };
  user?: UserType;
  handleMouseEnter: (item: {
    name: string;
    url: string;
    target?: string;
  }) => void;
  handleMouseLeave: (item: {
    name: string;
    url: string;
    target?: string;
  }) => void;
  shownReportMenu: boolean;
};

type UserMenuProps = {
  shownUserMenu: boolean;
  showUserMenu: () => void;
  hideUserMenu: () => void;
  scoutsCount: number;
  interestsCount: number;
  isSignedIn?: boolean;
  hasNotification: boolean;
  user?: UserType;
  signOut?: () => void;
};

type SearchBarProps = {
  shownSearch: boolean;
  showSearch: () => void;
  hideSearch: () => void;
  handleSearchSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
};

const menuItems = [
  {name: '募集を探す', url: routes.RECRUITINGS},
  {name: '企業を探す', url: routes.COMPANIES},
  {name: 'ES・体験記', url: routes.REPORTS},
  {name: '就活コラム', url: routes.COLUMNS},
  {name: 'コミュニティ', url: routes.QUESTIONS},
  {name: '相談室', url: routes.BOX_ANSWERS},
  {name: 'エンジニア', url: routes.ENGINEER},
  {name: 'AI選考対策', url: routes.REPORT_ANALYTICS_SEARCH, isUpdate: true},
  {name: 'AI ESビルダー', url: routes.ENGINEER_AI_ES_BUILDER, isNew: true},
  {
    name: 'イベント',
    url: 'https://event.gaishishukatsu.com/',
    target: '_blank',
  },
];
const spMenuItems: Array<{
  name: string;
  url: string;
  isNew?: boolean;
  isUpdate?: boolean;
}> = [
  {
    name: 'AI ESビルダー',
    url: '/engineer/ai-es-builder',
    isNew: true,
  },
  {
    name: 'AI選考対策',
    url: '/report_analytics/search',
    isUpdate: true,
  },
  {
    name: 'Software Engineer 就活',
    url: '/engineer',
  },
  {
    name: '募集を探す',
    url: '/recruiting_info',
  },
  {
    name: '企業を探す',
    url: '/company',
  },
  {
    name: 'ES・体験記',
    url: '/reports',
  },
  {
    name: '就活コラム',
    url: '/column/categories',
  },
  {
    name: 'コミュニティ',
    url: '/questions',
  },
  {
    name: '相談室',
    url: '/box/answers',
  },
  {
    name: '各種設定・その他',
    url: '/settings',
  },
];

export const GeneralHeader: FC<Props> = ({className}) => {
  const {isPC} = useMediaQuery();
  const {user, isIdle, scoutsCount, interestsCount, isSignedIn, signOut} =
    useAuthAndCounts();
  const cookie = parseCookies();
  const {isValid} = validateCookie(cookie);
  const [isSSR, setIsSSR] = useState(true);
  useEffect(() => {
    setIsSSR(false);
  }, []);
  return (
    <>
      <div className={`fixed top-0 w-full z-50 ${className}`}>
        <div className="hidden pc:block">
          {!isSSR && !isValid && <GuestHeaderItem />}
        </div>

        <MainHeader
          user={user}
          isIdle={isIdle}
          isSignedIn={isSignedIn}
          scoutsCount={scoutsCount}
          interestsCount={interestsCount}
          isPC={isPC}
          signOut={signOut}
        />
      </div>
      {/* ヘッダー下のサイズ調整 */}
      <div
        className={`bg-background-1 ${isSignedIn ? 'pc:pb-[86px] pb-[64px]' : 'pc:pb-[136px] pb-[56px]'}`}
      />
    </>
  );
};

const MainHeader: FC<{user?: UserType} & Props> = ({
  user,
  isSignedIn,
  scoutsCount = 0,
  interestsCount = 0,
  isPC,
  signOut,
}) => {
  const [shownUserMenu, showUserMenu, hideUserMenu] = useBooleanState(false);
  const [openedSPMenu, {open, close}] = useDisclosure(false);
  const hasNotification = scoutsCount > 0 || interestsCount > 0;
  const {isLargeScreen} = useMediaQuery();
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState('');
  const [shownSearch, showSearch, hideSearch] = useBooleanState(false);
  const [shownReportMenu, showReportMenu, hideReportMenu] =
    useBooleanState(false);

  useEffect(() => {
    const queryParam: string =
      typeof router.query.q === 'string' ? router.query.q : '';
    setSearchQuery(queryParam);
  }, [router.query.q]);

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const encodedSearchQuery = encodeURIComponent(searchQuery);
    void router.push(`/search?q=${encodedSearchQuery}`);
  };

  const handleMouseEnter = (item: {
    name: string;
    url: string;
    target?: string;
  }) => {
    if (item.url === routes.REPORTS) showReportMenu();
  };

  const handleMouseLeave = (item: {
    name: string;
    url: string;
    target?: string;
  }) => {
    if (item.url === routes.REPORTS) hideReportMenu();
  };

  return (
    <>
      <div className="flex pc:px-12 pc:pb-8 pc:pt-24 bg-background-1 flex-col justify-center items-center">
        <div
          className={`flex w-full pc:max-w-[1200px] h-[68px] bg-white text-black-1  justify-center px-12 pc:px-24 pc:rounded-[24px] pc:shadow-header shadow-nav py-16`}
        >
          <div className="flex w-[1200px] self-stretch justify-between items-center text-sm">
            {!isLargeScreen && (
              <div className="flex items-center">
                <button
                  onClick={open}
                  className="pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
                >
                  <MdMenu size={24} />
                </button>
                <SPDrawer
                  opened={openedSPMenu}
                  onClose={close}
                  user={user}
                  scoutsCount={scoutsCount}
                  interestsCount={interestsCount}
                  isSignedIn={isSignedIn}
                  signOut={signOut}
                />
              </div>
            )}
            <a href={routes.HOME}>
              <img
                className="max-w-[80px]"
                src={
                  isPC || !isSignedIn
                    ? LOGO_IMAGE_PATH_FULL
                    : LOGO_IMAGE_PATH_SQUARE
                }
              />
            </a>
            {isLargeScreen && (
              <div className="flex justify-between items-center gap-20">
                {menuItems.map((item) => (
                  <MenuItem
                    key={item.name}
                    item={item}
                    user={user}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    shownReportMenu={shownReportMenu}
                  />
                ))}
              </div>
            )}
            <div className="flex gap-8">
              {!shownSearch &&
                (isLargeScreen ? (
                  <UserMenu
                    shownUserMenu={shownUserMenu}
                    showUserMenu={showUserMenu}
                    hideUserMenu={hideUserMenu}
                    scoutsCount={scoutsCount}
                    interestsCount={interestsCount}
                    isSignedIn={isSignedIn}
                    hasNotification={hasNotification}
                    user={user}
                    signOut={signOut}
                  />
                ) : (
                  !isSignedIn && <LoginRegistButton />
                ))}
              {isPC && (
                <SearchBar
                  shownSearch={shownSearch}
                  showSearch={showSearch}
                  hideSearch={hideSearch}
                  handleSearchSubmit={handleSearchSubmit}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              )}
              {isSignedIn ? (
                <div className="relative flex items-center">
                  <button
                    onClick={async () => router.push('/scouts')}
                    className="pc:hover:opacity-80 pc:hover:duration-300 pc:hover:ease-in-out"
                  >
                    <MdMail size={24} />
                  </button>
                  {scoutsCount > 0 && (
                    <div className="absolute right-0 top-0 flex h-8 w-8 rounded-full bg-notification" />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {user && user.isDemoUser && (
          <div className="bg-accent-2 p-4 w-full">
            <p className="text-base text-black text-center">
              現在プレビュー閲覧用のアカウントでログインしています。プレビュー閲覧以外を行いたい場合は個人のアカウントでログインし直してください。
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const MenuItem: FC<MenuItemProps> = ({
  item,
  user,
  handleMouseEnter,
  handleMouseLeave,
  shownReportMenu,
}) => (
  <div
    onMouseEnter={() => {
      handleMouseEnter(item);
    }}
    onMouseLeave={() => {
      handleMouseLeave(item);
    }}
    className="relative"
  >
    <a
      href={
        item.url === routes.RECRUITINGS && user?.isEngineer
          ? `${item.url}?tagIDs=3`
          : item.url === routes.REPORTS
            ? '#'
            : item.url
      }
      target={item.target}
    >
      <span className="font-bold">{item.name}</span>
      {item.isNew && (
        <Box className="absolute text-accent-1 top-[-16px] left-0 right-0 mx-auto text-xs">
          <Text className="text-center">New</Text>
        </Box>
      )}
      {item.isUpdate && (
        <Box className="absolute text-accent-1 top-[-16px] left-0 right-0 mx-auto text-xs">
          <Text className="text-center">Update</Text>
        </Box>
      )}
      {item.target && (
        <span className="inline-flex">
          <FaExternalLinkAlt />
        </span>
      )}
    </a>
    {shownReportMenu && item.url === routes.REPORTS && (
      <div
        className={`absolute ${user && 'top-[10px]'} w-[100px] z-50 ml-[-12px] pt-12`}
      >
        <div className="overflow-hidden rounded-12 bg-background-1 shadow-modal">
          <HeaderReportMenu />
        </div>
      </div>
    )}
  </div>
);

const UserMenu: FC<UserMenuProps> = ({
  shownUserMenu,
  showUserMenu,
  hideUserMenu,
  scoutsCount,
  interestsCount,
  isSignedIn,
  hasNotification,
  user,
  signOut,
}) => {
  return isSignedIn ? (
    <div
      className="relative flex items-center gap-8"
      onMouseEnter={showUserMenu}
      onMouseLeave={hideUserMenu}
    >
      <div className="relative">
        <MdAccountCircle size={24} />
        {hasNotification && (
          <div className="absolute right-0 top-0 flex h-8 w-8 rounded-full bg-notification" />
        )}
      </div>
      <div className="text-sm font-bold">{user?.familyName}さん</div>
      {shownUserMenu && (
        <div className="absolute right-0 top-[21px] z-50 pt-12">
          <div className="overflow-hidden rounded-12 bg-white shadow-modal">
            <HeaderMenu
              onItemClick={hideUserMenu}
              scoutsCount={scoutsCount}
              interestsCount={interestsCount}
              showMyPage={isSignedIn}
              onLogout={signOut}
              className="w-[184px] text-black"
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    <LoginRegistButton />
  );
};

const SearchBar: FC<SearchBarProps> = ({
  shownSearch,
  showSearch,
  hideSearch,
  handleSearchSubmit,
  searchQuery,
  setSearchQuery,
}) => {
  const defaultDuration = 500;
  const [duration, setDuration] = useState(defaultDuration);
  const searchBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        hideSearch();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hideSearch]);

  const scaleX = {
    in: {opacity: 1, transform: 'scaleX(1)'},
    out: {opacity: 0, transform: 'scaleX(0)', transitionDuration: '0.1s'},
    common: {transformOrigin: 'right'},
    transitionProperty: 'transform, opacity',
  };

  return (
    <div className="flex items-center" onClick={showSearch} ref={searchBarRef}>
      {!shownSearch && <IoSearchCircleOutline size={24} />}
      <Transition
        mounted={shownSearch}
        transition={scaleX}
        duration={duration}
        timingFunction="ease"
        onEntered={() => {
          setDuration(0);
        }}
      >
        {(styles: React.CSSProperties) => (
          <form onSubmit={handleSearchSubmit} style={styles}>
            <Input
              icon={<FaSearch />}
              radius="xl"
              size="xs"
              className="text-sm w-[200px]"
              placeholder="企業や募集、コラムを検索"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </form>
        )}
      </Transition>
    </div>
  );
};

const GuestHeaderItem: FC = () => (
  <div className="flex w-full bg-background-footer justify-center p-16">
    <div className="flex w-[1200px] justify-between items-center text-white text-sm">
      <div>
        外資・日系トップ企業を目指す学生のための就職活動サイト
        「外資就活ドットコム」
      </div>
      <div className="flex items-center gap-8">
        <a
          href="https://go.gaishishukatsu.com/biz"
          target="_blank"
          rel="noopener noreferrer"
        >
          採用担当者様はこちら
        </a>
        <div className="ml-[-6px] mr-24 cursor-pointer">
          <FaExternalLinkAlt />
        </div>
      </div>
    </div>
  </div>
);

const SPDrawer: FC<
  {opened: boolean; onClose: () => void; user?: UserType} & Props
> = ({
  opened,
  onClose: close,
  user,
  scoutsCount = 0,
  interestsCount = 0,
  isSignedIn,
  signOut,
}) => {
  return (
    <Drawer
      position="left"
      withCloseButton={false}
      opened={opened}
      onClose={() => {
        close();
      }}
      padding={0}
      size="70%"
      className="top-52"
      {...overlayConfig}
    >
      <div className="h-full p-16 text-black-1 bg-background-2">
        <div className="overflow-hidden rounded-12 bg-white-1 p-12">
          {user && (
            <div className="flex gap-8 px-12 pb-4 pt-12">
              <MdAccountCircle size={24} />
              <div className="font-bold">{user.familyName}さん</div>
            </div>
          )}
          <HeaderMenu
            showMyPage={isSignedIn}
            scoutsCount={scoutsCount}
            interestsCount={interestsCount}
            onLogout={signOut}
            className="w-full bg-white-1"
          />
        </div>
        <ul className="mt-12 flex flex-col divide-y divide-line">
          {spMenuItems.map((item) => {
            return (
              <li key={item.name} className="p-12">
                <a
                  href={
                    // エンジニア学生にはデフォルト「エンジニア志望向け」にチェックが入っている状態で遷移させる
                    item.url === '/recruiting_info' && user?.isEngineer
                      ? `${item.url}?label_ids=3`
                      : item.url
                  }
                  className="flex items-center font-bold leading"
                >
                  {item.isNew && <MdOutlineFiberNew size={36} />}
                  <span className="ml-8">{item.name}</span>
                </a>
              </li>
            );
          })}
          {!isSignedIn && (
            <li className="p-12">
              <a
                href="https://go.gaishishukatsu.com/biz"
                className="flex items-center font-bold leading"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="ml-8">採用担当者様はこちら</span>
                <span>
                  <FaExternalLinkAlt />
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </Drawer>
  );
};

const LoginRegistButton: FC = () => {
  const [shownAuthActionMenu, showAuthActionMenu, hideAuthActionMenu] =
    useBooleanState(false);
  return (
    <div
      className="relative py-4"
      onMouseEnter={showAuthActionMenu}
      onMouseLeave={hideAuthActionMenu}
    >
      <Button
        className="text-accent-1 bg-transparent hover:bg-transparent pc:text-sm pc:bg-accent-2 pc:text-white pc:hover:bg-accent-1 pc:px-12 px-0"
        radius="xl"
      >
        ログイン・新規登録
      </Button>
      {shownAuthActionMenu && (
        <div className="absolute top-[40px] z-50 pt-12 left-[-20px]">
          <div className="overflow-hidden pc:rounded-12 bg-background-1 shadow-modal">
            <HeaderAuthActionMenu />
          </div>
        </div>
      )}
    </div>
  );
};
